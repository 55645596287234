import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import indexLogin from "./index/login";
import signInfo from "./index/signInfo";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/signInfo/adminTCK",
    component: () =>
      import(/* webpackChunkName: "admin-index" */ "v/index/Index.vue"),
    children: [...signInfo]
  },
  {
    path: "/login",
    name: "index-login",
    component: () => import("v/index/login/Index.vue"),
    children: [...indexLogin]
  },
  {
    path: "/printatc/:sfz_num",
    name: "printAtc",
    meta: {
      title: "准考证"
    },
    component: () => import("v/index/printAtc/index.vue")
  },
  {
    path: "/letter",
    name: "letter",
    meta: {
      title: "安全承诺书"
    },
    component: () => import("v/index/letter/index.vue")
  }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // 记录上一页
  if (from) {
    store.commit("setPrePage", from.path);
  }
  // 1.修改页面标题
  document.title = to.meta.title;
  // 判断是否是后台系统

  let tokenKey = "index-token",
  toLogin = "/login",
    token = localStorage.getItem(tokenKey);
    console.log(to.path);
    console.log(to.meta.authorization);
  if (to.meta.authorization && to.meta.authorization === true) {
    store.commit("setPrePage", to.path);
    // 2.1.获取登录信息
    if (token == null) {
      // 2.1.1.未登录，跳转到登录页面
      return next(toLogin);
    } else {
      next();
    }
  } else {
    return next();
  }
});

export default router;
