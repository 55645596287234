export default [
  {
    path: "/signInfo/adminTCK",
    name: "AdminTCK",
    component: () =>
      import(
        /* webpackChunkName: "admintck" */ "v/index/signInfo/AdminTCK.vue"
      ),
    meta: {
      title: "打印准考证-2022年清华大学特殊类型招生考试准考证下载网站",
      authorization: true
    }
  }
];
