import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./config/element";
import "./store/rem";
import htmlToPdf from "./utils/htmlToPdf"
Vue.use(htmlToPdf)
import VueBarcode from "vue-barcode";
Vue.component("barcode", VueBarcode);
import JsBarcode from "jsbarcode";
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
