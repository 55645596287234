export default [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "v/index/login/Login.vue"),
    meta: {
      title: "登录-2022年清华大学特殊类型招生考试准考证下载网站"
    }
  }
];
